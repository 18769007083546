//~ Import style
import './Login.scss';

const Login = () => {
  return (
    <section className="login">
      <h1>Connexion</h1>
    </section>
  );
};

export default Login;

