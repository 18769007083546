//& Import SCSS
import './Spinner.scss';
import { ReactComponent as SpinnerSVG } from './Spinner.svg';
import { ReactComponent as LogoSpinnerSVG } from './LogoSpinner.svg';
// import jpgContent from '../../Assets/img/feuilles.jpg';

const Spinner = () => {
  return (
    <>
      {/* <SpinnerSVG /> */}
      {/* <LogoSpinnerSVG /> */}
    </>
  );
}

export default Spinner;

